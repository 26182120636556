<template>
  <div id="app">
    <!--公共头部-->
    <comHead :isLogin="isLogin" :homeHead="homeHead" @nologin="nologin"></comHead>
    <div>
      <keep-alive include="development">
        <router-view @userLogin="userLogin" @headBg="headBg" @nologin="nologin" />
      </keep-alive>

      <!-- <comQR v-show="$route.meta.hideQR !== true"></comQR> -->
    </div>
    <!--公共底部-->
    <comFoot v-show="$route.meta.hideFoot !== true"></comFoot>
  </div>
</template>
<script>
import comHead from "@/components/comHead";
import comFoot from "@/components/comFoot";
// import comQR from "@/components/comQR.vue"
export default {
  name: "App",
  components:{
    comHead,
    comFoot,
    // comQR
  },
  data(){
    return{
      isLogin: false,
      homeHead: false,
    }
  },
  methods:{
    // 判断当前设备
    // isMobile(){
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //   return flag
    // },
    userLogin(){
      this.isLogin = true;
    },
    headBg(data){
      this.homeHead = data;
    },
    nologin(){
      this.isLogin = false;
    }
  },
  mounted() {
    this.isLogin = localStorage.getItem("user_login");
    // if(this.isMobile){
    //   console.log('mobile')
    //   this.$router.replace('/indexH5')
    // }else{
    //   console.log('pc')
    //   this.$router.replace('/')
    // }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #F0F2F5;
}

</style>
